import React from 'react';
import Container from '../components/Container/Container';
import Link from '../components/Link';
import Title from '../components/Title/Title';
import PageLayout from '../templates/PageLayout';

export default function NotFound() {
  return (
    <PageLayout>
      <Container>
        <Title>This page could not be found</Title>
        <p style={{ textAlign: 'center' }}>
          <Link href="/">Go back home</Link>
        </p>
      </Container>
    </PageLayout>
  );
}
